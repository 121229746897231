import type { avatarColorVariant } from '@/definitions/_general/_data/avatarColorVariant'
import type { PropType } from '@vue/runtime-core'
import type { RouteLocationRaw } from 'vue-router'
import type { dayVariant } from '@/definitions/_general/_data/day'
import type { ArrayAsConstValuesType } from '@/definitions/_general/_types/types'
import type { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import type emojisMap from '@/assets/emojisMap.json'
import type { avatarStatusVariant } from '@/definitions/_general/_data/avatarStatusVariant'

export type EmojiNameType = typeof emojisMap[number]

export type Size = '' | 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'xMedium' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge' | 'full'
export type SizeProp<S extends Size> = PropType<S>

export type IconRotate = 45 | 90 | 180 | 295

export type IconSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge'
export type EmojiSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge'
export type ChipSize = 'xSmall' | 'small' | 'large'
export type FlagSize = '' | 'xxSmall' | 'xSmall' | 'small' | 'large'
export type AvatarSize = 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
export type FileSize = 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
export type ButtonSize = '' | 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

export type DetailsHeroPattern = 'blue' | 'green' | 'mint' | 'orange' | 'red' | 'violet' | 'salmon' | 'purple' | 'purpur' | 'pink'

export type TabStyle = 'pills' | 'pillsOutlined' | 'default'

export type AvatarStatusVariant = typeof avatarStatusVariant[number]
export type AvatarColorVariant = typeof avatarColorVariant[number]
export type AvatarColors = ArrayAsConstValuesType<typeof avatarColorVariant>

export type PriorityType = typeof priorityVariant[number]

export type TextWeight = 'medium' | 'semi-bold' | 'bold'
export type TextColor = 'green' | 'red'
export type TextArray = string | {
  text: string,
  style?: TextWeight,
  color?: TextColor,
  link?: RouteLocationRaw,
  message?: string
}

export const fileExtensions = [
  'audio',
  'doc',
  'json',
  'mail',
  'pdf',
  'image',
  'presentation',
  'text',
  'video',
  'xls',
  'archive',
  'html',
]
export type TmFileExtension = ArrayAsConstValuesType<typeof fileExtensions>

export const imageExtensions = ['png', 'jpeg', 'jpg']
export type ImageExtensions = typeof imageExtensions[number]

export type Tab = {
  name: string
  counterClass?: string
  counter?: number
  label?: string
  to?: RouteLocationRaw
  error?: boolean
}

export type RadioTab = {
  name: string;
  label?: string;
  logos?: string[];
  text?: string;
}

export type FlagCountry = {
  id: string;
  name: string;
  counter?: number;
  code?: string;
  customIcon?: string;
}

export type ListChipRecipientTypes = 'list' | 'contact' | 'recipient'
export type ListChipRecipient = {
  type: ListChipRecipientTypes;
  firstName?: string;
  lastName?: string;
  phone: string;
  name?: string;
  country?: FlagCountry;
  [key: string]: any;
}
export interface TableHeaders {
  text: string
  group?: boolean
  value: string
  format?: (payload: string) => string
  hideSortable?: boolean
  class?: string
  cellClass?: string
  width?: number | string
  minWidth?: number | string
  expandPossible?: boolean
  hidden?: boolean
  required?: boolean
}

export type BreadcrumbsLink = {
  label: string;
  name?: string;
  url?: string;
}

export type ListChip = {
  label: string;
  style?: string;
  icon?: string;
  hint?: number | string;
}

export type ItemGroupType = {
  uid: string | number;
  isDefault?: boolean;
  [key: string]: any;
}

export type DetailsTableItemType = {
  label: string;
  value: any;
  slot?: string;
  rightClass?: string;
}

export type ResultTableItemType = {
  label: string;
  value: any;
  slot?: string;
  labelTop?: boolean;
}

export type StepperStep = {
  name: string
  to?: string
}

export type StatusType<T extends string = string> = {
  name: T;
  color: string;
  outline: boolean;
  isDefault?: boolean;
}

export type PersonType = {
  uid: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatar: string;
  avatarColor: AvatarColorVariant;
  status?: AvatarStatusVariant;
  email?: string;
}

export type DayVariant = typeof dayVariant[number]

export type ScheduleTime = {
  startTime: string
  endTime: string
}

export type DaySchedule = {
  day: DayVariant,
  active: boolean,
  schedule: ScheduleTime[]
}

export type MatchTable = 'contacts' | 'carrierLookup'

export type CardStats = {
  title: string
  value: string | number
  percent?: {
    value: number
    up: boolean
  },
}

export type UploadMethod = {
  type: string
  icon: string
  title: string
  description: string
}

export type VerticalTableItemType = {
  label: string
  value: any
}

export type TmAttachmentType = {
  downloadable: boolean;
  removable?: boolean;
  error?: boolean;
  extension: TmFileExtension;
  url?: string;
  title: string;
  size: string;
  loading?: boolean;
}

export type ButtonColor =
  | 'blue'
  | 'primary'
  | 'blue-outline'
  | 'secondary'
  | 'red'
  | 'error'
  | 'orange'
  | 'warning'
  | 'green'
  | 'success'
  | 'white'
  | 'orange500'
  | 'shade'
  | 'yellow'
  | 'transparent'
  | 'transparent-note'
  | 'transparent-light'
  | 'transparent-dark'
  | 'transparent-border'
  | 'blueish'
  | 'text-link'

export type ButtonAlign = 'left' | 'right' | 'center' | 'around' | 'between' | 'evenly' | undefined

export type ChipColor =
  | 'beta'
  | 'blue-fill'
  | 'gray'
  | 'blue'
  | 'primary'
  | 'red'
  | 'error'
  | 'orange'
  | 'warning'
  | 'green'
  | 'success'
  | 'purpur'
  | 'brown'
  | 'cyan'
  | 'violet'

export type RecipientType = {
  name: string;
  phone?: string;
  avatar?: string;
  avatarColor?: string;
  clearable?: boolean;
}
