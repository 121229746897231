import type {
  ChatSidebarBulkAction,
  ChatSidebarBulkActionsNames,
  ChatSidebarHeaderDropdownNames,
  ChatSidebarHeaderDropdownType, MessengerFilterItem
} from '@/definitions/chats/sidebar/types'
import { MessengerFilterItemNames } from '@/definitions/chats/sidebar/types'

export const chatSidebarFilterItemsList: MessengerFilterItem[] = [
  {
    routeName: 'base.chats.waiting-now',
    label: 'Waiting now',
    shortLabel: 'waiting',
    isMissed: true,
    additionalText: 5,
    name: MessengerFilterItemNames.waiting,
  },
  {
    routeName: 'base.chats.open',
    shortLabel: 'open',
    label: 'Open',
    additionalText: 25,
    name: MessengerFilterItemNames.open,
  },
  {
    routeName: 'base.chats.unread',
    label: 'Unread',
    shortLabel: 'unread',
    isMissed: true,
    additionalText: 2,
    name: MessengerFilterItemNames.unread,
  },
  {
    routeName: 'base.chats.unassigned',
    shortLabel: 'unassigned',
    label: 'Unassigned',
    additionalText: 16,
    name: MessengerFilterItemNames.unassigned,
  },
  {
    routeName: 'base.chats.assigned-to-me',
    shortLabel: 'assigned to you',
    label: 'Assigned to me',
    additionalText: 12,
    name: MessengerFilterItemNames.assigned,
  },
  {
    routeName: 'base.chats.solved',
    label: 'Solved',
    shortLabel: 'solved',
    additionalText: 32,
    name: MessengerFilterItemNames.solved,
  },
  {
    routeName: 'base.chats.all-chats',
    label: 'All chats',
    shortLabel: '',
    additionalText: 235,
    name: MessengerFilterItemNames.allChats,
  },
]

export const chatSidebarBulkActionsList: Record<ChatSidebarBulkActionsNames, ChatSidebarBulkAction> = {
  assign: {
    icon: 'tmi-person-assign',
    text: 'Assign',
  },
  unread: {
    icon: 'mark_as_unread',
    text: 'Unread',
  },
  read: {
    icon: 'drafts',
    text: 'Read',
  },
  reopen: {
    icon: 'refresh',
    text: 'Reopen',
  },
  solve: {
    icon: 'task_alt',
    text: 'Solve',
  },
  pin: {
    icon: 'tmi-pin',
    text: 'Pin',
  },
  delete: {
    icon: 'delete',
    text: 'Delete',
  },
}

export const ChatSidebarHeaderDropdownMap: Record<ChatSidebarHeaderDropdownNames, ChatSidebarHeaderDropdownType> = {
  select: {
    icon: 'check_box',
    label: 'Select chats',
  },
  solveAllMissed: {
    icon: 'task_alt',
    label: 'Solve all missed chats',
  },
  solveAllRead: {
    icon: 'task_alt',
    label: 'Solve all read chats',
  },
  markAsRed: {
    icon: 'drafts',
    label: 'Mark all as red',
  },
}
