import type { RouteRecordName } from 'vue-router'

export enum MessengerFilterItemNames {
  waiting = 'waiting',
  open = 'open',
  unread = 'unread',
  unassigned = 'unassigned',
  assigned = 'assigned',
  solved = 'solved',
  allChats = 'allChats',
}

export type MessengerFilterItem = {
  routeName: RouteRecordName;
  label: string;
  shortLabel: string;
  isMissed?: boolean;
  additionalText: number;
  name: MessengerFilterItemNames;
}
// selected items actions
export enum ChatSidebarBulkActionsNames {
  assign = 'assign',
  unread = 'unread',
  read = 'read',
  reopen = 'reopen',
  solve = 'solve',
  pin = 'pin',
  delete = 'delete',
}
export type ChatSidebarBulkAction = {
  icon: string;
  text: string;
}

// header dropdown actions
export enum ChatSidebarHeaderDropdownNames {
  select = 'select',
  solveAllMissed = 'solveAllMissed',
  solveAllRead = 'solveAllRead',
  markAsRed = 'markAsRed',
}

export type ChatSidebarHeaderDropdownType = {
  icon: string;
  label: string;
}
