export const messageDetailsSent = [
  {
    label: 'Message ID',
    value: '62471259',
  },
  {
    label: 'Sent time',
    value: '8 Jul 10:44 am',
  },
  {
    label: 'From',
    value: '(207) 555-0119',
  },
  {
    label: 'To',
    value: {
      url: '',
      fullName: 'John Doe',
      avatarColor: 'orange',
    },
  },
  {
    label: 'Country',
    value: {
      id: 'us',
      name: 'United States',
    },
  },
  {
    label: 'Message content',
    value: 'Hello, can I get more information here?',
  },
  {
    label: 'Source',
    value: 'Web App',
  },
  {
    label: 'Total charachters',
    value: '18 charachters',
  },
  {
    label: 'Message parts',
    value: '2 parts',
  },
  {
    label: 'Encoding',
    value: 'Plain text',
  },
  {
    label: 'Status',
    value: {
      name: 'Received',
      color: 'green',
      outline: true,
    },
  },
] as const

export const messageDetailsReceived = [
  {
    label: 'Message ID',
    value: '62471259',
  },
  {
    label: 'Receive time',
    value: '8 Jul 10:44 am',
  },
  {
    label: 'From',
    value: {
      url: '',
      fullName: 'John Doe',
      avatarColor: 'orange',
    },
  },
  {
    label: 'To',
    value: '(207) 555-0119',
  },
  {
    label: 'Message content',
    value: 'Hello, can I get more information here?',
  },
  {
    label: 'Status',
    value: {
      name: 'Received',
      color: 'green',
      outline: true,
    },
  },
] as const

export const messageDetailsCall = [
  {
    label: 'Call ID',
    value: '62471259',
  },
  {
    label: 'Direction',
    value: {
      outcoming: false,
      callType: 'ended',
    },
  },
  {
    label: 'Call time',
    value: '04/06/22 08:10 AM',
  },
  {
    label: 'From',
    value: '(207) 555-0119',
  },
  {
    label: 'To',
    value: {
      url: '',
      fullName: 'Darlene Robertson (836) 422-5544',
      avatarColor: 'orange',
    },
  },
  {
    label: 'Country',
    value: {
      id: 'us',
      name: 'United States',
    },
  },
  {
    label: 'Price',
    value: '$0.188 / minute',
  },
  {
    label: 'Call length',
    value: '00:01:12',
  },
  {
    label: 'Total cost',
    value: '$0.031',
  },
] as const
